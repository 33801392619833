import React from "react";
import Link from "next/link";
import Image from "next/image";
import * as Sentry from "@sentry/nextjs";

import PageWrapper from "@/components/PageWrapper";
import Container from "@/components/Container";
import Typography from "@/components/Typography";
import Button from "@/components/Button";
import { routePath } from "@/helpers/routePath";
import MetaHead from "@/components/MetaHead";
import { defaultOGImage } from "@/helpers/pageMetaData";
import styles from "src/pages/Error.module.scss";
import Recommended from "@/components/Recommended";
import QuestionMarkIcon from "@/icons/question-mark.svg";
import { getImgixURL } from "@/helpers/getImgixURL";

const ErrorPage = ({ statusCode, hasGetInitialPropsRun, err }) => {
  if (!hasGetInitialPropsRun && err) {
    // getInitialProps is not called in case of
    // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured
    Sentry.captureException(err);
    // Flushing is not required in this case as it only happens on the client
  }
  statusCode !== 404 && Sentry.captureMessage(`user dostał nową 500`, "fatal");

  //TODO: update with endpoint data
  const metaData = {
    title: statusCode,
    description:
      "Wczytuj się w artykuły, dzięki którym lepiej zrozumiesz, wokół czego kręci się świat kultury popularnej, wsłuchuj się w audycje radiowe na żywo w opcji 24/7 i ekscytuj się tysiącami godzin podcastów - to jakieś pół roku słuchania bez przerwy! Dodatkowo, dowiaduj się więcej o artystach, korzystając z naszej bazy muzycznej.",
    ogFbTitle: "newonce - 404",
    ogFbImage: defaultOGImage,
  };

  const isClientSide = typeof window !== "undefined";

  return (
    <PageWrapper>
      <div className={styles.wrapper}>
        <MetaHead metaData={metaData} />
        <Container className={styles.container}>
          <Image
            src={getImgixURL(
              "https://cdn.newonce.me/uploads/images/46404/Hobbies_Plant.png"
            )}
            layout="fixed"
            width={160}
            height={160}
            alt="not found"
          />

          <Typography variant="h4" component="h3" className={styles.heading}>
            Coś poszło nie tak...
          </Typography>
          <Typography variant="p" component="p" className={styles.desc}>
            {statusCode === 404
              ? "Staraliśmy się znaleźć wybraną stronę, ale coś nie zadziałało. Spróbuj odświeżyć stronę lub przejdź na stronę główną."
              : "Staraliśmy się znaleźć wybraną stronę, ale coś nie zadziałało. Potrzebujemy trochę więcej czasu na naprawę tego błędu. Na ten moment polecamy sprawdzić treści poniżej."}
          </Typography>

          {statusCode === 404 && (
            <Link href={routePath.FEED.href}>
              <a className={styles.link}>
                <Button variant="secondaryStroke" className={styles.button}>
                  Wróć na stronę Główną
                </Button>
              </a>
            </Link>
          )}
        </Container>
      </div>
      <Typography variant="medium" className={styles.errorHelp}>
        <QuestionMarkIcon className={styles.helpIcon} />
        <span>
          Daj nam znać
          <button
            className={styles.helpButton}
            onClick={isClientSide && window?.zE?.activate}
          >
            tutaj
          </button>
          jeśli problem się powtarza. Będziemy mogli dużo szybciej naprawić nasz
          błąd.
        </span>
      </Typography>
      {statusCode !== 404 && <Recommended />}
    </PageWrapper>
  );
};

ErrorPage.getInitialProps = async ({ res, err, asPath }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;

  if (err) {
    Sentry.captureException(err);

    // Flushing before returning is necessary if deploying to Vercel, see
    // https://vercel.com/docs/platform/limits#streaming-responses
    await Sentry.flush(2000);

    return { statusCode };
  }
  // If this point is reached, getInitialProps was called without any
  // information about what the error might be. This is unexpected and may
  // indicate a bug introduced in Next.js, so record it in Sentry
  Sentry.captureException(
    new Error(`_error.js getInitialProps missing data at path: ${asPath}`)
  );
  await Sentry.flush(2000);

  return { statusCode };
};

export default ErrorPage;
